import React from "react";
import { connect } from "react-redux";

import { getClientAccount, changeAttribute } from "./_actionsAndReducers";
import Card from "../_common/Card";
import PostEditingQualities from "./PostEditingQualities";
import AllowQualities from "./AllowQualities";
import Field from "../_common/Field";
import Invitation from "./Invitation";
import DialectsHandler from "./DialectsHandler";
import DueDate from "./DueDate";

const machineEngineOptions = [
  ["Google Translate", "google"],
  ["Amazon Translate", "amazon"],
  ["Microsoft Translator Text", "microsoft"],
  ["DeepL", "deep_l"],
  ["GPT-4o", "chat_gpt4"],
  ["Anthropic", "anthropic"],
  ["Tolq 3B", "tolq_3b"],
];

export class ClientAccount extends React.Component {
  constructor(props) {
    super(props);

    const currencyConfig = props.staticData.currencyConfirmed ?
      { label: "Currency", attribute: "currencyCode", type: "none" }
      :
      { label: "Currency", attribute: "currencyCode", type: "select", options: [["American dollars ($)", "USD"], ["Euros (€)", "EUR"]] };

    this.clientFields = [
      { label: "Auto client review", attribute: "autoClientReview", type: "checkbox", },
      { label: "Allow having editors", attribute: "allowedToHaveEditors", type: "checkbox", },
      { label: "Payment method", attribute: "paymentMethod", type: "select", options: [["Bank transfer", "bank_transfer"], ["Credit card", "credit_card"]] },
      currencyConfig,
      { label: "Machine quality engine", attribute: "machineTranslationEngine", type: "select", options: machineEngineOptions },
      { label: "Postediting engine", attribute: "posteditingTranslationEngine", type: "select", options: machineEngineOptions },
    ];

    this.state = {
      editingName: false,
      newName: ""
    };
  }

  componentDidMount(){
    const clientAccountId = document.location.pathname.split("/")[2];

    this.props.getClientAccount(clientAccountId);
  }

  render() {
    if (!this.props.clientAccount.id) return <span>Loading...</span>;

    const {
      clientAccount: { id, name, internalName },
      staticData: { clientsAppUrl, clientRequireLegacyMode, clientEventMode, clientTmMode },
      changeAttribute,
    } = this.props;

    const {
      editingName,
      editingInternalName,
      newName,
      newInternalName,
    } = this.state;

    return (
      <>
        <a
          href={`${clientsAppUrl}become/${id}`}
          className="btn btn-primary btn-lg pull-right"
          target="_blank" rel="noreferrer"
        >
          Become client
        </a>

        {editingName ?
          <form
            className="form-inline mb-4"
            onSubmit={() => {
              changeAttribute("name", newName);
              this.setState({ editingName: false });
            }}
          >
            <input type="text" className="form-control-lg form-control" value={newName} onChange={e=>this.setState({newName: e.target.value})} />
            <button className="btn btn-bg btn-link" onClick={() => this.setState({ editingName: false })} type="button">Cancel</button>
            <button className="btn btn-bg btn-primary"> Save </button>
          </form>
          :
          <>
            <h1 className="mb-4">
              {name}
              <small>
                <a
                  href="#"
                  onClick={e => {e.preventDefault(); this.setState({editingName: true, newName: name});}}
                  className="ticon-edit ml-2"
                />
              </small>
            </h1>
          </>
        }

        {
          !editingInternalName ? (
            <>
              <p>Iternal name:</p>
              <h5 className="mb-4">
                {internalName}
                <small>
                  <a
                    href="#"
                    onClick={e => {e.preventDefault(); this.setState({editingInternalName: true, newInternalName: internalName});}}
                    className="ticon-edit ml-2"
                  />
                </small>
              </h5>
            </>
          ) : (
            <form
              className="form-inline mb-4"
              onSubmit={() => {
                changeAttribute("internalName", newInternalName);
                this.setState({ editingInternalName: false });
              }}
            >
              <input type="text" className="form-control-lg form-control" value={newInternalName} onChange={e=>this.setState({newInternalName: e.target.value})} />
              <button className="btn btn-bg btn-link" onClick={() => this.setState({ editingInternalName: false })} type="button">Cancel</button>
              <button className="btn btn-bg btn-primary"> Save </button>
            </form>
          )
        }

        {(!clientRequireLegacyMode && !clientEventMode && !clientTmMode) ?
          <p>
            <em className="mb-3">This client still defaults to legacy mode.</em>
          </p>
          :
          (clientRequireLegacyMode ?
            <p>
              <em className="mb-3">This client requires <strong>legacy mode</strong> for all requests</em>
            </p>
            :
            (clientEventMode ?
              <p>
                <em className="mb-3">This client requires <strong>event mode</strong> for all requests</em>
              </p>
            :
              (clientTmMode && (
                  <p>
                    <em className="mb-3">This client requires <strong>tm mode</strong> for all requests</em>
                  </p>
                )
              )
            )
          )
        }

        <Card className="mb-4">
          <div className="row">
            {this.clientFields.map(field =>(
              <Field
                key={field.attribute}
                {...{
                  ...field,
                  value: this.props.clientAccount[field.attribute],
                  onChange: value => changeAttribute(field.attribute, value)
                }}
              />
            ))}
            <DialectsHandler />
            <DueDate/>
            <label className="col-3 mb-3 font-weight-bold">Account Manager Email:</label>
            <div className="col-3 mb-3">
              <input
                  name="accountManagerEmail"
                  type="text"
                  className="form-control"
                  value={this.props.clientAccount["accountManagerEmail"] || ""}
                  onChange={e => changeAttribute("accountManagerEmail", e.target.value)}
              />
            </div>
          </div>
        </Card>

        <h5 className="mb-2">Qualities settings</h5>
        <Card className="mb-5">
          <div className="row">
            <AllowQualities />
            <PostEditingQualities />
          </div>
        </Card>
        <Invitation />
      </>
    );
  }
}

export default connect(
  ({ clientAccount, staticData }) => ({ clientAccount, staticData }),
  { getClientAccount, changeAttribute }
)(ClientAccount);
