import React from "react";
import { connect } from "react-redux";

import TableSortableHeader from "../_common/TableSortableHeader";
import { requestFiltersUpdate } from "../_common/filtered_lists/_actions";

const headers = [
  { attribute: "id", sortable: true},
  { attribute: "name", sortable: true},
  { attribute: "internal name", sortable: true},
  { attribute: "created_at", sortable: true, label: "Created at"},
  { attribute: "inactive_tasks", sortable: false, label: "Inactive tasks"},
  { attribute: "whitelisted", sortable: false},
  { attribute: "Mode", sortable: false},
  { attribute: "csv", sortable: false, label: " "},
];

export class Headers extends React.Component {
  render() {
    const { requestResults, sortAttribute, sortOrder } = this.props;

    return (
      <TableSortableHeader {...{ sortAttribute, sortOrder, headers, requestResults }} />
    );
  }
}

export default connect(
  ({ filters: { sortAttribute, sortOrder } }) => ({ sortAttribute, sortOrder }),
  {
    requestResults: (sortOrder, sortAttribute) => requestFiltersUpdate({ page: 1, sortOrder, sortAttribute })
  }
)(Headers);
