import React from "react";
import { connect } from "react-redux";

import InactiveFor from "./InactiveFor";
import Whitelisted from "./Whitelisted";
import {CLIENT_MODES_NAMES} from "./_constants";

export class ClientsList extends React.Component {
  render() {
    const { clients, order_month} = this.props;

    return (
      <tbody>
        {clients.map(({ id, name, internalName, createdAt, invoiceManagementClientPath, clientPath, lastUnfinishedTasks, csvPath, clientLanguages, mode }) => (
          <tr key={`client-${id}`}>
            <td>
              <a href={order_month ? invoiceManagementClientPath : clientPath }>
                {id}
              </a>
            </td>
            <td>{name}</td>
            <td>{internalName}</td>
            <td>{createdAt}</td>
            <td>
              <InactiveFor lastUnfinishedTasks={lastUnfinishedTasks} />
            </td>
            <td>
              <Whitelisted clientLanguages={clientLanguages} id={id} />
            </td>
            <td>{CLIENT_MODES_NAMES[mode]}</td>
            <td> <a href={csvPath}>csv</a> </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default connect(
  ({ clients, filters: { order_month } }) => ({ clients, order_month })
)(ClientsList);
